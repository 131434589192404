import { tw } from '@/utils/tw';
import { default as BaseVideoBaseContent } from 'base/components/VideoBaseContent/theme';

const CoverBox = tw.theme({
  extend: BaseVideoBaseContent.CoverBox,
  slots: {
    base: 'block gap-2 bg-transparent wings-white text-preamble-xs md:text-preamble-sm [&_a]:text-summer-red-600',
    toggle: 'bg-white text-preamble-xs md:text-preamble-sm',
    headline: 'mb-1 uppercase text-headline-md md:mb-2',
  },
});
const TrendingMenu = tw.theme({
  extend: BaseVideoBaseContent.TrendingMenu,
  base: 'mx-0',
});

const Breadcrumbs = tw.theme({
  extend: BaseVideoBaseContent.Breadcrumbs,
});

const VideoBaseContent = tw.theme({
  extend: BaseVideoBaseContent,
});

export default Object.assign(VideoBaseContent, { CoverBox, TrendingMenu, Breadcrumbs });
