import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';

const Prose = BaseCoverBox.Prose;

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: 'bg-linen-100 wings-none max-w-full p-0 sm:p-0',
    headline: 'uppercase text-black text-headline-md sm:text-headline-xl',
    toggle: 'bg-linen-100 text-summer-red-600 text-preamble-xs sm:text-preamble-sm',
  },
});

export default Object.assign(CoverBox, { Prose });
