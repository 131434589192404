import { StandaloneMarkup } from 'base/components/Markup';
import useUserAuth from 'lib/hooks/useUserAuth';
import { useUserName } from 'lib/hooks/useUserName';

export const FeminaStandaloneMarkup: typeof StandaloneMarkup = ({ markup, ...props }) => {
  const { isUserLoggedIn } = useUserAuth();
  const { firstName } = useUserName();

  const showMemberMarkup = isUserLoggedIn && markup?.includes('id="femina-member-welcome"');

  const memberMarkup = (
    <div className="flex flex-col items-center gap-1 bg-linen-300 px-6 py-3">
      <h2 className="text-headline-md">Hej {firstName}</h2>
      <div className="text-preamble-sm text-center">
        Vill du hantera dina print-prenumerationer?{' '}
        <a href="https://prenumerera.se/kundservice/" className="font-bold hover:underline">
          Det kan du göra här
        </a>
      </div>
    </div>
  );

  return showMemberMarkup ? memberMarkup : <StandaloneMarkup {...{ markup }} {...props} />;
};
