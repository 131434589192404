import { tw } from '@/utils/tw';
import BaseBreadcrumbs from 'base/components/Breadcrumbs/theme';

const Breadcrumbs = tw.theme({
  extend: BaseBreadcrumbs,
  slots: {
    base: `text-preamble-sm`,
    separator: `text-preamble-sm`,
  },
});

export default Breadcrumbs;
